.primary-text {
  color: #979797 !important;
  display: block;
  font-size: 14px;
}
.secondary-text {
  color: #979797 !important;
  font-weight: bold;
  font-size: 14px;
}
.search-screen {
  background-color: white;
}
.car-image {
  width: 100%;
  max-width: 500px;
  height: auto;
  z-index: 999;
  margin-top: -70px;
}
.car-image-container {
  position: relative;
  max-width: 350px;
  margin-right: auto;
  margin-left: auto;
  height: 140px;
  overflow: hidden;
  margin-bottom: -14px;
}
.top-area {
  padding: 20px;
}
.notification-area {
  text-align: right;
  min-height: 50px;
}
@media only screen and (min-width: 600px) {
  .notification-area {
    max-width: 500px;
    margin-left: auto;
    margin-right: auto
  }
  .related-cars{
    height: auto!important;
  }
}

.alert-action {
  color: #008eff;
  background-color: white;
  border-radius: 15px;
  border: 1px solid #008eff;
  padding: 2px 36px 2px 8px;
  position: relative;
}
.alert-wrapper {
  position: absolute;
}
.align-alert {
  position: absolute;
  right: 0;
}
.take-action {
  color: white;
  background-color: #ff3b4f;
  border-radius: 15px;
  padding: 2px 20px;
}
.Schade {
  background-color: #e00000;
  padding: 2px 23%;
}
.no-notification {
  color: #50e3c2;
}
.notification-wrapper {
  margin-bottom: 10px;
}
.right-content {
  flex: 2 0 0;
  text-align: center;
  margin-top: 7px;
  
}
.sub-nav-bar {
  padding-top: 20px;
  display: flex;
  flex-direction: row;
}

.car-state-title {
  color: #008eff;
  font-size: 16px;
  font-weight: bold;
}
.current-car-state {
  padding: 0 40px 40px 40px;
  background-color: white;
}
@media only screen and (min-width: 600px) {
  .current-car-state {
    max-width: 500px;
    margin: 0 auto;
  }
  .related-cars{
    height: auto!important;
  }
}

.flex-box {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
.related-cars {
  padding: 20px;
  background-color: #f7f7f7;
  min-height: 200px;
  margin-top: 20px;
}

.car-carousel-slide img {
  width: 100%;
  height: auto;
}
.car-inner-container {
  width: 1000px;
}

.car-carousel-slide {
  width: 169px;
  display: inline-block;
  height: auto;
}
.car-carousel {
  overflow: hidden;
  overflow-x: scroll;
}

.car-carousel-license-plate {
  color: #979797;
  font-weight: bold;
}

.edit-button {
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center; /* align horizontal */
  align-items: center;
  height: 50px;
  background-color: #97cc04;
  text-align: center;
  vertical-align: -webkit-baseline-middle;
  color: white;
}

.related-cars-title {
  margin-bottom: 20px;
  text-align: center;
}

.edit-form-item-label {
  color: #008eff;
  font-weight: 600;
  margin-bottom: 7px;
}
.simple-form-input {
  width: 100%;
  border-radius: 0;
}
.edit-form-item {
  margin-bottom: 30px;
}

.edit-form-item-bottom {
  background-color: #f7f7f7;
}

.simple-textarea {
  background-color: transparent !important;
}
.ant-btn.ant-btn-primary {
  background-color: #97cc04;
  border: none;
  width: 100%;
}
.ant-modal-footer {
  border-top: 0;
  border: none !important;
  padding: 0 !important;
}
.ant-modal-footer div > button:first-child {
  display: none;
}
.ant-modal-body {
  padding: 0 !important;
}
.edit-form {
  padding: 24px;
}
.edit-form-item-bottom {
  padding: 24px;
  padding-bottom: 46px;
}
.ant-modal-footer button {
  margin-bottom: 0;
  height: 50px;
  margin-left: 0 !important;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.edit-form-item .take-action {
  color: white;
  background-color: #6666ff;
  border-radius: 15px;
  padding: 2px 38px;
  cursor: pointer;
}

.edit-form-item {
  position: relative;
}

.edit-form-item .notification-wrapper {
  position: absolute;
  right: 20px;
  top: 10px;
}

.left-content {
  cursor: pointer;
  color: #008eff;
  padding-left: 40px;
  font-size: 17px;
  position: relative;
}
.back-button {
  position: absolute;
  left: 20px;
  top: 2px;
}

.sub-menu {
  background-color: white;
}

.top-area {
  background-color: white;
}

.column:last-child{
  padding-right: 0;
}
.tanken{
display: flex;
flex-direction: row;
flex-wrap: wrap;
width: 100%;
}

.simple-select{
border:none;
border-bottom:1px solid #979797;
border-radius: 0;
background-color:transparent;
}

.simple-select:after{
  content:"marlowe"

}

.tank-button{
  background: linear-gradient(90deg, #008EFF 19.9%, #0D41FD 91.62%);
  border-radius: 10px 0px 0px 10px;
  width: 100%;
  height: 35px;
  border:none;
  color:white;
  cursor: pointer;
  margin-top: 18px;
  text-align: center;
}

.tank-dropdown{
  padding-left:20px;
  display: flex;
flex-direction: row;
flex-wrap: wrap;
width: 100%;

}
.col-3 {
  flex-grow: 0;
  align-self: flex-end;
  max-width: 30%;
  flex-basis: 30%;
}
.col-6 {
  flex-grow: 0;
  max-width: 50%;
  flex-basis: 50%;
}

.liters{
  padding-left: 30px;
}

.col-9 {
  flex-grow: 0;
  max-width: 70%;
  align-self: flex-end;
  flex-basis: 70%;
}

.hide{
  display: none;
}

.car-edit-form .show{
  display: inline-block;
}
.dashboard.car-edit-form .ant-btn{
  
}
.activity-label{

  margin-top:10px;
  font-size: 12px;
line-height: 16px;
/* identical to box height */
text-align: center;

color: rgba(124, 135, 165, 0.598222);

}

.ant-modal{
  top:72px!important;
}

.row{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}
.row .col-6{
  padding-right: 15px
}
.row .col-6:last-child{
  padding-right:0;
  padding-left:15px;
}

.ant-timeline-item {
  position: relative;
  margin: 0px;
  margin-left: -85px;
}