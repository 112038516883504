.foo-menu {
  position: fixed;
  z-index: 1000 !important;
}

.menu-active .top-nav-bar {
  z-index: 80;
}

.mobileHomeScreen .license-plate {
  padding-left: 20px;
  height: 62px;
  /* left:20px; */
  padding-left: 50px;
  margin: 0 auto;
}

.bottom-area form {
  width: 100%;
}

.license-plate button {
  background-color: #2b4c9c;
  position: absolute;
  left: 0;
  top: 0;
  text-align: center;
  border-top-left-radius: 37px;
  border-bottom-left-radius: 37px;
  width: 50px;
  height: 62px;
  font-size: 25px;
  border: 1px;
  padding:0;
  color: white;
}

.license-plate input {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 30px 0px;
  background-color: #f0b504 !important;
  border: none;
  border-radius: 0;
  border-top-right-radius: 37px;
  border-bottom-right-radius: 37px;
  font-size: 20px;
  font-weight: bold;
  height: 62px;
  text-align: center;
}
.top-area-home {
}
.top-nav-bar {
  position: relative;
  background-color: #008eff !important;
  color: #fff;
  height: 73px !important;
}
.am-navbar-title {
  color: #fff !important;
}
.menu-mask {
  content: " ";
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.4;
  z-index: 79;
}

.bottom-area {
  margin: 20px;
  position: relative;
  display: flex;
  justify-content: center;
}
.hiker-text-area {
  margin-top: 40%;
  width: 70%;
  padding-left: 50px;
  border-top: 3px solid #008eff;
 
}
@media only screen and (min-width: 600px) {
  .hiker-text-area {
    margin-top: 200px;
   
  }
}
.date {
  color: #979797;
  font-size: 14px;
  font-weight: bold;
  margin-top: -24px;
  display: block;
}
.hiker-title {
  font-size: 32px !important;
  margin-bottom: 0 !important;
}

.hiker-subtitle {
  margin-top: 0 !important;
  font-size: 18px !important;
  color: #979797 !important;
  font-weight: normal !important;
}
.mobileHomeScreen ::-webkit-input-placeholder {
  color: #757575 !important;
}

.mobileHomeScreen :-moz-placeholder {
  /* Firefox 18- */
  color: #757575 !important;
}

.mobileHomeScreen ::-moz-placeholder {
  /* Firefox 19+ */
  color: #757575 !important;
}

.mobileHomeScreen :-ms-input-placeholder {
  /* IE 10+ */
  color:#757575 !important;
}

.mobileHomeScreen ::-ms-input-placeholder {
  /* Edge */
  color: #757575!important;
}

.mobileHomeScreen :placeholder-shown {
  /* Standard one last! */
  color: #757575 !important;
}
.ant-badge-status-dot {
  position: relative;
  top: -1px;
  display: inline-block;
  width: 13px!important;
  height: 13px!important;
  vertical-align: middle;
  border-radius: 50%;
}

.workOrderDetailMobile{
  height: 100%;
    width: 100%;
    position: fixed;
    background-color: white;
    z-index: 999;
    top: 0;
    left:0;
    overflow: scroll;
    padding-bottom:60px;
}

body #afrondenForm input::placeholder {
  color: #cbcbcb!important;
}

body #afrondenForm textarea::placeholder {
  color: #cbcbcb!important;
}

.mobilemenu{
  background-color: #000734;
  padding-top: 50px;
  position:fixed;
  height: 100%;
  bottom:0;
  top:0;
  width: 100%;  
  color:white;
  font-size: 30px;
  font-weight: 500;
  animation: cubic-bezier(.17,.67,.83,.67);
}
.mobilemenu ul{
 list-style: none;
 margin-top: 50px;
}


.ant-btn-primary[disabled] {
  color: rgba(0, 0, 0, 0.25);
    background: #f5f5f5;
    border-color: #d9d9d9;
    text-shadow: none;
    box-shadow: none;
}


.workOrderDetailMobile .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  width: 100%;
  height: 32px;
  padding: 0 0px;
}

.mobileHomeScreen .am-tab-bar-bar .am-tab-bar-tab-title{
  font-size: 12px;
  font-weight: 600;
}

.history-vehicle-list{
 list-style-type: none;
 margin: 0;
 padding: 0;
 width: 270px;
}

.history-vehicle-list li{
  text-overflow: ellipsis;
  font-weight: bold;
  font-size: 12px;
  line-height: 20px;
  color: #979797;
  height: 50px;
  text-overflow: ellipsis;
  white-space: nowrap; 
  overflow: hidden;
 }
 .history-vehicle-list li span{
  text-overflow: ellipsis;
  overflow: none;
  white-space: nowrap; 
 }

 .mobileHomeScreen .bottom-area{
  height: 80px;
 }

 .list-sub-text{
  font-weight: bold;
  font-size: 12px;
  white-space: nowrap; 
  color: #979797;
  width: 120px;
 }