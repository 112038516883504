/* @font-face {
  font-family: "Open Sans";
  src: local("MyFont"), url(fonts/MyFont.woff) format("woff");
} */
html{
  font-family: 'Manrope', sans-serif!important;
}
body {
  margin: 0;
  padding: 0;
  font-family: 'Manrope', sans-serif!important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white !important;
}
body,
html,
.full-height,
#root {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.full-height-with-menu {
  height: calc(100% - 73px);
}
.ant-table-expanded-row .ant-table-thead {
  background-color:#fbfbfb;
}