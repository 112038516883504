.login-form .ant-form-item {
  box-sizing: border-box;
  margin: 0px;
  padding: 0;
  margin-top: -22px!important;
  color: rgba(0, 0, 0, 0.65);
  font-size: 16px!important;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
  font-feature-settings: 'tnum', "tnum";
  margin-bottom: 47px!important;
  vertical-align: top;
}

.login-screen {
  height: 100%;
  background-color: white;
}
.login-area {
  padding: 20px 20px;
  height: 40%;
  min-height: 200px;
  border-top-right-radius: 200px;
  border-top-left-radius: 200px;
  background-color: #008eff;
}

.top-section {
  height: 60%;
  background-color: white;
  min-height: 150px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  box-shadow: 0 0 30px white inset !important;
  background-color: white !important;
}
.login-form .ant-input{
  height: 45px!important;
}
.username-input {
  display: block;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 5px;
  box-shadow: rgb(11, 107, 242) 0px 0px 0px 1px inset;
  top: 24px;
  height: 40px;
  width: 100%;
}
.login-form-button {
  width: 100%;
  background-color: #2e5bff !important;
  height: 50px !important;
}

.hikes-logo {
  margin-bottom: 50px;
}


html{
  background-color: #f4f6fc; 
}
.dashboard-screen{
 background-color: #f4f6fc; 
  height: 100%;
}

.body-content{
  padding-left: 225px;
  padding-right: 20px;
}

.card-space{
  margin-bottom: 50px;
}

.dashboard-card{
  box-shadow: rgba(4, 4, 4, 0.1) 0px 0px 5px 0px;
  border-radius: 5px;
}

.ant-table thead > tr > th{
  background:transparent;
  font-weight: bold;
}

.page-header{
  margin:0px;
  margin-top: 10px;
}

.search-kenteken input::placeholder {
  color: #cbcbcb!important;
}