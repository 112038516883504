.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}
.simple-form-input {
  background-color: 0!important;
  border: none!important;
  border-bottom: 1px solid #979797!important;
  color: rgba(124, 135, 165, 0.598222)!important;
  font-size: 14px!important;
  line-height: 19px!important;
  text-align: justify!important;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.am-navbar-title {
  font-weight: bold;
}
.my-drawer {
  position: relative;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.spinner{
    position: fixed !important;
    top: calc( 50% - ( 50px / 2) ); /* where ... is the element's height */
    right: calc( 50% - ( 50px / 2) ); /* where ... is the element's width */
}

.ant-menu-item .anticon, .ant-menu-submenu-title .anticon {
  font-size: 20px!important;
}

.ant-card .ant-input-affix-wrapper-lg {

  border-radius: 20px!important;
}

.historie-switcher .ant-select-selection-item{
  height: 50px;
}

.ant-statistic-title{
  font-weight: 700!important;
}

.ant-table-row:hover{
  cursor: pointer;
}

.kentekenTag{
  font-size: 14px!important;
  font-weight: bolder!important;
  color: #2b4c9c!important;
  background-color: #f0b504!important;
  border-color: #2b4c9c!important;
  border-radius: 2px!important;
}

input::placeholder {
  color: #f0f0f0;
}