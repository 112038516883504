
.opdrachtenTable .ant-table thead > tr > th {
  background-color: #fafafa!important;
  font-weight: bold;
}

.ant-drawer-content {
  height: 100%;
}

.workorderwizard input::placeholder {
  color: #cbcbcb!important;
}

.workorderwizard textarea::placeholder {
  color: #cbcbcb!important;
}

.workorderwizard .zoek-huidige-locatie{
  text-decoration: underline;
  color:#36a5ff!important;
  font-weight: 800;

}
.workorderwizard .zoek-huidige-locatie:hover{
  text-decoration: underline;
  color:#8bcbff!important;
  font-weight: 800;

}
.workorderwizard .zoek-huidige-locatie:visited{
  color:inherit
}

.workorderwizard .ant-message{
   font-weight: 800!important;
}