
.allcars-area{
  padding:0 20px;
}
@media only screen and (min-width: 600px) {
  .upload-area {
    max-width: 500px;
    margin-left: auto;
    margin-right: auto
  }
  .related-cars{
    height: auto!important;
  }
}