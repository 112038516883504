

.login-form .ant-form-item {
  box-sizing: border-box;
  margin: 0px;
  padding: 0;
  margin-top: -22px!important;
  color: rgba(0, 0, 0, 0.65);
  font-size: 16px!important;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
  font-feature-settings: 'tnum', "tnum";
  margin-bottom: 47px!important;
  vertical-align: top;
}

.login-screen {
  height: 100%;
  background-color: white;
}
.login-area {
  padding: 20px 20px;
  height: 40%;

}

.top-section {
  height: 60%;
  background-color: white;
  min-height: 150px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  box-shadow: 0 0 30px white inset !important;
  background-color: white !important;
}
.login-form .ant-input{
  height: 45px!important;
}
.username-input {
  display: block;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 5px;
  box-shadow: rgb(11, 107, 242) 0px 0px 0px 1px inset;
  top: 24px;
  height: 40px;
  width: 100%;
}
.login-form-button {
  width: 100%;
  background-color: #2e5bff !important;
  height: 50px !important;
}

.hikes-logo {
  margin-bottom: 50px;
}

@media screen and (min-width: 600px) {

  body .login-screen {
    background-color:none;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto
  }

  body .login-area {
    padding: 20px 20px;
    height: auto;
    min-height: 200px;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    background-color: transparent ;
  }
}

.login-screen .ant-form-item .ant-form-item-label > label {
  font-family: Manrope;
font-style: normal;
  font-weight: 800;
font-size: 14px;
line-height: 18px;
color: #B0BAC9;
letter-spacing: 2px!important;
text-transform: uppercase;
}

.login-header{
  font-family: Manrope;
font-style: normal;
font-weight: 800;
font-size: 28px;
line-height: 40px;
/* or 143% */

text-align: center;
font-feature-settings: 'liga' off;

/* Sharp blue */

color: #008EFF;
}

.autozoeksubtitle{
  font-size: 18px;
  line-height: 28px;
  /* identical to box height, or 156% */
  font-feature-settings: 'liga' off;
  
  color: #979797;
}